<template>
  <div>
    <div class="section section__company">
      <div class="section__wrapper">
        <div class="title-with-icon title-with-icon_menu mb-5">
          <div class="title-with-icon__icon title-with-icon__icon_back"></div>
          <div class="title-with-icon__title"><a href="/sveden">Сведения об образовательной организации</a></div>
          <div class="title-with-icon__delimeter">/</div>
          <div class="title-with-icon__title">Документы</div>
        </div>
        <template v-if="blocks">
<span v-for="block in blocks" :key="block.id">
          <div v-if="block.title" class="content_title content_700 mb-5">{{ block.title }}</div>
          <div class="content_18 mb-7" v-html="block.description"></div>
          <div v-if="block.documents" class="container-grid grid-3 mb-5 grid-md-1">
            <div v-for="doc in block.documents" :key="doc.id" class="container-grid__item">
              <div class="doc fill-white p-24 container-shadow">
                <div class="doc__content row">
                  <div class="col-auto text-center">
                    <div class="ext-wrapper m-0">
                      <img src="/pic/icon-doc.svg"/>
                    </div>
                  </div>
                  <div class="col">
                    <div class="doc__title">{{ doc.name }}</div>
                  </div>
                </div>
                <div class="doc__actions">
                  <a :itemprop="doc.itempop ? doc.itempop : 'url'" :href="doc.file" class="btn btn-outline-primary">Скачать</a>
                </div>
              </div>
            </div>
          </div>
        </span>
        </template>
        <div class="my-5 d-block d-md-none">
          <a class="history-back" href="/sveden">Вернуться</a>
        </div>
        <div class="section__subsection_blue section__company-nav">
          <div class="section__subsection-wrapper">
            <company-navigation />
          </div>
        </div>
      </div>
    </div>
    <page-footer/>
  </div>
</template>

<script>
import PageFooter from "../../components/PageFooter";
import ApiService from "../../../services/api.service";
import CompanyNavigation from "../../components/CompanyNavigation";
export default {
  name: "InfoDocs",
  components: {CompanyNavigation, PageFooter},
  data() {
    return {
      blocks: null
    }
  },
  mounted() {
    ApiService.get('infocategories/document').then(res => {
      this.blocks = res.data.info_blocks
      this.$parent.init()
    })
  }
}
</script>

<style lang="scss">
  .section__company {
    h4 {
      font-size: 3.2rem;
      font-weight: 700;
      margin-top: 7rem!important;
      &.no-margin {
        margin: -40px 0 20px!important;
      }
    }
    .content_18 {
      a {
        color: #ed1b2f;
        text-decoration: underline;
      }
    }
  }
</style>